import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';
import { ScopeField } from './ScopeField';

export default function ParnterFormTokenList(props) {
  return (
    <List {...props} perPage={10}>
      <Datagrid rowClick='show'>
        <TextField source='identifier' label='identifier' />
        <BooleanField label='Send DOI Email' source='sendDOIEmail' />
        <ScopeField source='scope' />
      </Datagrid>
    </List>
  );
}
