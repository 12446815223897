import React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, ArrayInput, SimpleList, SimpleFormIterator } from "react-admin";

export default function CreatePartnerFormToken(props) {
  const handleDataTransform = (data) => {
    const newData = { ...data };
    const { creditLead } = newData.extra;

    creditLead.tracking.agent = creditLead.tracking.agent?.replace(/\s/g, "");
    creditLead.tracking.team = creditLead.tracking.team?.replace(/\s/g, "");

    if (!creditLead.tracking.agent && !creditLead.tracking.team) {
      creditLead.tracking = {};
    }

	newData.allowedDomains = newData.allowedDomains.map(allowedDomain => allowedDomain.domain.trim());

    return newData;
  };
  return (
    <Create
      title="Partnerformular Token erstellen"
      transform={(data) => {
        return handleDataTransform(data);
      }}
      {...props}
    >
		<p>URI folgt dem folgenden Format: https://example.com. Subdomains sind erlaubt.</p>
      <SimpleForm>
        <TextInput source="identifier" label="Identifier" required />
        <TextInput source="domain" label="Domain (URI)" required />
        <TextInput source="partnerId" label="Partner ID" required />
		<BooleanInput source="sendDOIEmail" label="DOI E-Mail versenden" required defaultChecked={true} defaultValue={true} />
		<ArrayInput source="allowedDomains" label="Zulässige Domains">
			<SimpleFormIterator inline>
				<TextInput source="domain" label="Domain (URI)" />
			</SimpleFormIterator>
		</ArrayInput>
        <h3>Tracking for Creditlead</h3>
        <TextInput source="extra.creditLead.tracking.agent" label="Agent" />
        <TextInput source="extra.creditLead.tracking.team" label="Team" />
      </SimpleForm>
    </Create>
  );
}
