import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import DataProvider from './DataProvider';
import AuthProvider from './AuthProvider';
import Layout from './Layout/Layout';
import LoginPage from './Layout/LoginPage';

import './styles/concat.scss';
import config from './config';
import Dashboard from './Components/Dashboard';
import CreditLeadsList from './Components/CreditLeadsList';
import LeadsCredit from './Components/EditView/LeadsCredit';
import CreditCardLeadsList from './Components/CreditCardLeadsList';
import LeadsCreditCard from './Components/EditView/LeadsCreditCard';
import BaufiLeadsList from './Components/BaufiLeadsList';
import LeadsBaufi from './Components/EditView/LeadsBaufi';
import ContactFormLeadsList from './Components/ContactFormLeadsList';
import CCLeadsList from './Components/CCLeadsList';
import LeadsContactForm from './Components/EditView/LeadsContactForm';
import { LeadsCCLead } from './Components/EditView/LeadsCCLead';
import UsersList from './Components/UsersList';
import User from './Components/EditView/User';
import CreateUser from './Components/CreateResource/CreateUser';
import EditUser from './Components/EditView/EditUser';
import CreatePartnerToken from './Components/CreateResource/CreatePartnerToken';
import CreateWebsiteToken from './Components/CreateResource/CreateWebsiteToken';
import PartnerTokenList from './Components/PartnerTokenList';
import WebsiteTokenList from './Components/WebsiteTokenList';
import ParnterFormTokenList from './Components/PartnerFormTokenList';
import CreatePartnerFormToken from './Components/CreateResource/CreatePartnerFormToken';

const dataProvider = DataProvider(config.API_URL, AuthProvider.getHttpClient());
const App = () => (
  <BrowserRouter>
    <Admin
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      layout={Layout}
      loginPage={LoginPage}
      dashboard={Dashboard}
      requireAuth
      disableTelemetry
    >
      {(permissions) => (
        <>
          <Resource name='leads' options={{ label: 'Leads' }} />
          <Resource
            name='leads/credits'
            options={{ label: 'Kredite', childOf: 'leads' }}
            list={CreditLeadsList}
            show={LeadsCredit}
          />
          <Resource
            name='leads/creditcards'
            options={{ label: 'Kreditkarten', childOf: 'leads' }}
            list={CreditCardLeadsList}
            show={LeadsCreditCard}
          />
          <Resource
            name='leads/baufi'
            options={{ label: 'Baufinanzierungen', childOf: 'leads' }}
            list={BaufiLeadsList}
            show={LeadsBaufi}
          />
          <Resource
            name='leads/form'
            options={{ label: 'Kontaktformular', childOf: 'leads' }}
            list={ContactFormLeadsList}
            show={LeadsContactForm}
          />
          <Resource
            name='leads/ccLeads'
            options={{ label: 'Kreditkarten Consors', childOf: 'leads' }}
            list={CCLeadsList}
            show={LeadsCCLead}
          />
          {permissions.includes('admin') && (
            <>
              <Resource
                name='admin/users'
                options={{ label: 'Users' }}
                list={UsersList}
                create={CreateUser}
                edit={EditUser}
                show={User}
              />
              <Resource
                name='admin/token/partners'
                options={{ label: 'Partners' }}
                list={PartnerTokenList}
                create={CreatePartnerToken}
              />
              <Resource
                name='admin/token/websites'
                options={{ label: 'Websites' }}
                list={WebsiteTokenList}
                create={CreateWebsiteToken}
              />
              <Resource
                name='admin/token/partner-forms'
                options={{ label: 'Partner Formulare' }}
                list={ParnterFormTokenList}
                create={CreatePartnerFormToken}
              />
            </>
          )}
        </>
      )}
    </Admin>
  </BrowserRouter>
);

export default App;
